<template>
  <div
    v-loading="loading"
    class="rate-table"
  >
    <ElAlert
      v-if="error"
      :closable="false"
      type="error"
      show-icon
    >
      There was an error loading this rate table.
      <a href="javascript:location.reload();">Reload</a> the page or <TfCtaEmailSupport />
    </ElAlert>
    <template v-if="!error && tierGroup && !loading">
      <h5 v-if="!isPlanSummary && !rateAttributeIndex">
        <img
          class="logo"
          :src="incumbent
            ? currentProduct.project_product.inforce_product.carrier.logo_url
            : carrierLogoUrl
          "
          :alt="incumbent
            ? currentProduct.project_product.inforce_product.carrier.name
            : carrierName
          "
        >
      </h5>
      <ElForm
        ref="form"
        :model="formModel"
        :rules="formRules"
        :validate-on-rule-change="false"
        label-width="100px"
        @submit.native.prevent
      >
        <table :class="[{ stacked : isStacked }, 'table-data-entry']">
          <thead>
            <template v-if="isStacked">
              <tr>
                <!-- Only show the subtype cell for non-composite rate attribute type -->
                <th
                  v-if="attributeType !== compositeType"
                  class="title"
                  :data-test="`rate entry table ${attributeType} subtype`"
                  v-text="attributeType"
                />
                <th class="title">
                  <!-- Show subtype dropdown for !incumbent, !readonly -->
                  <!-- and tier groups with multiple subtypes -->
                  <ElSelect
                    v-if="!incumbent && !readonly && subtypeOptions.length"
                    v-model="stackedAttributeType"
                    placeholder="Select type"
                  >
                    <ElOption
                      :value="compositeType"
                      :label="$options.filters.tierGroupName(compositeType)"
                    />
                    <ElOption
                      v-if="!isStopLoss"
                      :value="ageBandedType"
                      :label="$options.filters.tierGroupName(ageBandedType)"
                    />
                    <ElOption
                      v-if="stackedAttributeType === customType"
                      :value="customType"
                      :label="$options.filters.tierGroupName(customType)"
                    />
                  </ElSelect>
                  <template v-else>
                    {{
                      stackedAttributeType === ageBandedType
                        ? 'Age'
                        : stackedAttributeType | tierGroupName
                    }}
                  </template>
                </th>
                <th
                  :class="[{ 'proposed-title' : !incumbent }, 'title']"
                  v-text="incumbent ? 'In-Force rates' : 'Proposed rates'"
                />
              </tr>
            </template>
            <template v-else>
              <!-- Titles for Tier Groups that have multiple `subtypes` -->
              <tr v-if="tierSubtypes.length > 1">
                <th
                  v-for="subtype in tierSubtypes"
                  :key="subtype.name"
                  colspan="2"
                  class="sub-type-header"
                  :data-test="`rate entry table ${attributeType} subtype`"
                  v-text="subtype.name"
                />
              </tr>
              <!-- Titles for `subtypes`-->
              <tr>
                <!-- IF: Composite, Age Banded, and Custom -->
                <template v-if="[compositeType, ageBandedType, customType].includes(attributeType)">
                  <th
                    class="title"
                    :data-test="`rate entry table ${attributeType} subtype`"
                  >
                    {{ attributeType === ageBandedType ? 'Age' : attributeType | tierGroupName }}
                  </th>
                  <th :class="[{ 'proposed-title' : !incumbent }, 'title']">
                    {{ incumbent ? 'In-Force rates' : 'Proposed rates' }}
                  </th>
                </template>
                <!-- ELSE: need to loop through the subtypes  -->
                <template
                  v-for="(subtype, idx) in tierGroupInfo?.tier_subtypes"
                  v-else
                >
                  <th
                    :key="`${subtype.name}-${idx}-label`"
                    class="title"
                  >
                    <ElSelect
                      v-if="!incumbent
                        && !readonly
                        && ![compositeType, ageBandedType, customType].includes(attributeType)"
                      v-model="subtypeOptions[idx]"
                      placeholder="Select type"
                      @change="updateSubtypeData({
                        id: subtype.id,
                        cellGroupIdx: idx,
                        rateValueType: subtypeOptions[idx] })"
                    >
                      <ElOption
                        :value="compositeType"
                        :label="$options.filters.tierGroupName(compositeType)"
                      />
                      <ElOption
                        v-if="!isStopLoss"
                        :value="ageBandedType"
                        :label="$options.filters.tierGroupName(ageBandedType)"
                      />
                      <ElOption
                        v-if="(subtype.rate_values && subtype.rate_values[0].type === customType)
                          || (subtype.rate_value && subtype.rate_value.type === customType)"
                        :label="$options.filters.tierGroupName(customType)"
                        :value="customType"
                      />
                    </ElSelect>
                    <template v-else>
                      <!-- If not age banded, print && filter subtype type -->
                      <template v-if="subtypeOptions[idx] !== ageBandedType">
                        {{ subtypeOptions[idx] | tierGroupName }}
                      </template>
                      <!-- Otherwise, print `Age` -->
                      <template v-else>
                        Age
                      </template>
                    </template>
                  </th>
                  <th
                    :key="`${subtype.name}-${idx}-value`"
                    :class="[{ 'proposed-title' : !incumbent }, 'title']"
                    v-text="incumbent ? 'In-Force rates' : 'Proposed rates'"
                  />
                </template>
              </tr>
            </template>
          </thead>
          <tbody v-if="tableRows.length">
            <template v-if="isStacked">
              <template v-for="subtype in tableRows">
                <tr
                  v-for="(value, idx) in subtype.rateValue?.values"
                  :key="`${subtype.name}-${value.display_label}`"
                >
                  <!-- First td in stacked only shows on non-composite and only the first row.  -->
                  <td
                    v-if="!idx && subtype.name !== compositeType"
                    :rowspan="subtype.rateValue?.values.length"
                    v-text="subtype.name"
                  />
                  <RateEntryTdLabel
                    :key="`${storeAttributeId}-${idx}-${value.label}`"
                    :label="value.display_label"
                    :subtype-type="stackedAttributeType"
                  />
                  <RateEntryTdValue
                    :cell-data="value"
                    :form-key="`${subtype.name}-${value.label}`"
                    :readonly="incumbent || readonly"
                    :subtype-id="subtype.id"
                    @validateForm="validationTriggered"
                    @updateAttributes="updateAttributes"
                    @updateValue="value.value = $event"
                  />
                </tr>
              </template>
            </template>
            <template v-else>
              <tr
                v-for="(cellGroup, idx) in tableRows"
                :key="`${storeAttributeId}-${idx}`"
              >
                <!-- Each cellGroup is grouped in the table as 2 columns in the table -->
                <template v-for="(group, groupIdx) in cellGroup">
                  <RateEntryTdLabel
                    :key="`${storeAttributeId}-${groupIdx}-${group.label}`"
                    :label="group.display_label"
                    :subtype-type="subtypeOptions[groupIdx] || attributeType"
                  />
                  <RateEntryTdValue
                    :key="`${storeAttributeId}-${groupIdx}-value`"
                    :form-key="`${tierSubtypes[groupIdx].name}-${group.label}-${groupIdx}`"
                    :cell-data="group"
                    :readonly="readonly"
                    :subtype-id="tierSubtypes[groupIdx].id"
                    @validateForm="validationTriggered"
                    @updateAttributes="updateAttributes"
                    @updateValue="group.value = $event"
                  />
                </template>
              </tr>
            </template>
          </tbody>
        </table>
      </ElForm>
    </template>
  </div>
</template>

<script>
  import { mapState, mapActions, mapWritableState } from 'pinia';
  import { useCarrierInfoStore } from '@/stores/carrierInfo.js';
  import { useRateEntryStore } from '@/stores/rateEntry.js';
  import { useProductStore } from '@/stores/product.js';
  import { addBreadcrumb } from '@sentry/vue';
  import { reusableAgeBandedValues as ageBanded } from '@watchtowerbenefits/es-utils-public';
  import { smartProposals, uploadRenewalUploadEnhancements } from '@/utils/featureFlags.js';
  import RateEntryTdLabel from './RateEntryTdLabel.vue';
  import RateEntryTdValue from './RateEntryTdValue.vue';

  const ageBandedType = 'AgeBandedRateValue';
  const compositeType = 'CompositeRateValue';
  const customType = 'CustomRateValue';

  /**
   * Rate Entry Table
   *
   * @exports RateEntry/RateEntryTable
   */
  export default {
    name: 'RateEntryTable',
    components: { RateEntryTdLabel, RateEntryTdValue },
    inject: [
      'storeAttributeId',
      'isPlanSummary',
      'isPlan',
    ],
    props: {
      /**
       * Rate Attribute type only required for the proposal document to determine table layout
       */
      attributeType: {
        type: String,
        default: compositeType,
      },
      /**
       * Boolean used to set readonly for incumbent table
       */
      incumbent: {
        type: Boolean,
        default: false,
      },
      /**
       * Boolean used to display if the carrier icons should show.
       */
      rateAttributeIndex: {
        type: Number,
        default: 0,
      },
      /**
       * Tier Group name + subtypes used to loop through for table rows
       */
      tierGroup: {
        type: Object,
        default: () => ({}),
      },
    },
    data: () => ({
      ageBandedType,
      compositeType,
      customType,
      error: false,
      isStacked: false,
      loading: false,
      maxLength: 0,
      subtypeOptions: [],
      tableRows: [],
      tierGroupInfo: null,
      tierSubtypes: [],
      debounceTimers: {},
      staleTimers: false,
    }),
    computed: {
      ...mapState(useRateEntryStore, ['rateAttributes']),
      ...mapWritableState(useRateEntryStore, ['rateErrors', 'separatedClassIds']),
      ...mapState(useCarrierInfoStore, {
        carrierLogoUrl: 'logoUrl',
        carrierName: 'name',
      }),
      ...mapState(useProductStore, [
        'isUploadRenewalRatePassOrSmartProposal',
        'rateTierGroups',
        'currentProduct',
        'isStopLoss',
        'productId',
        'productState',
        'isProductNotStartedOrNotSubmitted',
      ]),
      /**
       * Age Banded array from shared repo for creating Age Banded Rows
       *
       * @returns {Array}
       */
      ageBanded,
      /**
       * Evaluate the smartProposals feature flag.
       *
       * @returns {boolean}
       */
      smartProposalEnabled() {
        return this.$ld.checkFlags(smartProposals);
      },
      /**
       * Evaluate the uploadRenewalUploadEnhancements feature flag.
       *
       * @returns {boolean}
       */
      uploadRenewalUploadEnhancementsEnabled() {
        return this.$ld.checkFlags(uploadRenewalUploadEnhancements);
      },
      /**
       * Boolean to determine layout
       *
       * @returns {boolean}
       */
      isComposite() {
        return this.attributeType === compositeType;
      },
      /**
       * Boolean to determine the state of the tables (review or edit)
       *
       * @returns {boolean}
       */
      readonly() {
        const reviewingProposal = !this.incumbent && this.$route.meta.readonly;

        return this.incumbent || reviewingProposal;
      },
      stackedAttributeType: {
        /**
         * Attribute type of a stacked tier group layout.
         * For non-incumbents, you can grab any rate value id id since all the attribute types should all be the same.
         * non-stacked layouts do not need this info.
         *
         * @returns {string}
         */
        get() {
          let attributeType;

          if (!this.isStacked) {
            return undefined;
          }

          // if product is stopLoss only Composite is allowed
          if (this.attributeType === compositeType || this.isStopLoss) {
            return compositeType;
          }

          if (this.incumbent) {
            const subtype = this.tierGroup.tier_subtypes[0];

            if (this.isPlan) {
              attributeType = subtype.rate_value.type;
            } else {
              attributeType = subtype.rate_values[0].values[0].type;
            }
          } else {
            const rateAttribute = this.rateAttributes[this.storeAttributeId];
            const subtypeId = this.tierGroupInfo.tier_subtypes[0].id;

            attributeType = rateAttribute.rateValues[subtypeId]?.type;
          }

          return attributeType;
        },
        /**
         * creates stacked attribute types
         *
         * @param {string} value
         */
        set(value) {
          this.loading = true;
          const values = [];

          this.tableRows = [];

          // Loop through each old subtype and clear from the store
          this.tierGroupInfo.tier_subtypes.forEach((subtype) => {
            this.clearRateAttributeRateValues({
              rateValueType: subtype.id,
              storeAttributeId: this.storeAttributeId,
            });
          });

          // IF: push the single composite value
          // ELSE: push each age banded values
          if (value === compositeType) {
            values.push(this.addCompositeRow());
          } else {
            this.ageBanded.forEach((age, idx) => {
              values.push(this.addAgeBandedRow(idx));
            });
          }

          this.tierGroupInfo.tier_subtypes.forEach((subtype) => {
            // Push updated values to the store before pushing objects to tableRows
            this.setRateAttributeRateValues({
              rateValueType: value,
              storeAttributeId: this.storeAttributeId,
              subtypeId: subtype.id,
              values,
            });

            this.tableRows.push({
              id: subtype.id,
              name: subtype.name,
              rateValueType: value,
              rateValue: { values },
            });
          });

          this.$nextTick(this.validationTriggered);
          this.loading = false;
        },
      },
      /**
       * Form model for validation against
       *
       * @returns {object}
       */
      formModel() {
        return Object.fromEntries(
          this.tableRows.flatMap(
            (row) => {
              if (Array.isArray(row)) {
                const formFields = [];

                this.tierSubtypes.forEach(({ id, name }) => {
                  formFields.push(...row.map(({ label }, i) => [
                    `${name}-${label}-${i}`,
                    this.rateAttributes[this.storeAttributeId].rateValues[id]?.values
                      .find(({ label: attributeLabel }) => label === attributeLabel)?.value,
                  ]));
                });

                return formFields;
              }

              return row.rateValue.values.map(({ value, label }) => [`${row.name}-${label}`, value]);
            },
          ),
        );
      },
      /**
       * Form validation rules
       *
       * @returns {object}
       */
      formRules() {
        const formRules = {};

        Object.keys(this.formModel).forEach((key) => {
          formRules[key] = [{
            required: true,
            message: 'Rates must be numerical',
            validator: (rule, value, callback) => {
              if (!Number.isNaN(parseFloat(value))) {
                callback();
              } else {
                callback(true);
              }
            },
          }];
        });

        return formRules;
      },
    },
    watch: {
      /**
       * Update rateErrors with invalid fields
       */
      rateErrors() {
        this.$nextTick(this.getInvalidFields);
      },
      /**
       * Watch the attributeType prop (2-tier, composite, smoker/non-smoker, etc) trigger `updateTierGroup()` when user updates the Rate Options dropdown
       * in the parent component.
       *
       * @param {string} newValue
       * @param {string} oldValue
       */
      attributeType(newValue, oldValue) {
        const values = [];
        const rowValues = [];
        let i = 0;
        let subtypeObj;

        this.loading = true;
        this.maxLength = (newValue === compositeType || this.isStopLoss)
          ? 1 : this.ageBanded.length;
        // reset table rows, subtype, subtype options, and tier group info
        this.tableRows = [];
        this.subtypeOptions = [];
        this.tierSubtypes = [];
        this.updateTierGroupInfo('attributeType');

        // Clear out age banded and composite rate values from the store
        // IF: Age/Composite, clear out single subtype
        // ELSE: Loop through old tier group
        if ([ageBandedType, compositeType, customType].includes(oldValue)) {
          this.clearRateAttributeRateValues({
            rateValueType: oldValue,
            storeAttributeId: this.storeAttributeId,
          });
        } else {
          // Loop through each old subtype and clear from the store
          const oldTierGroup = this.rateTierGroups
            .find((tierGroup) => tierGroup.tier_group_name === oldValue);

          oldTierGroup.tier_subtypes.forEach((subtype) => {
            this.clearRateAttributeRateValues({
              rateValueType: subtype.subtype_id,
              storeAttributeId: this.storeAttributeId,
            });
          });
        }

        // IF: Add Age banded or composite to tier subtype
        // ELSE: add each subtype to tierSubtypes and `AgeBandedRateValue` by default to the subtype options
        if ([ageBandedType, compositeType, customType].includes(newValue)) {
          this.tierSubtypes.push({
            id: newValue,
            name: newValue,
          });
        } else {
          this.tierGroupInfo.tier_subtypes.forEach((subtype) => {
            this.tierSubtypes.push({
              id: subtype.id,
              name: subtype.name,
            });
            const existingOption = this.isStacked && !this.subtypeOptions.includes(ageBandedType);

            // Push options if not stacked or stacked && !exists
            if ((!this.isStacked || existingOption) && !this.isStopLoss) {
              this.subtypeOptions.push(ageBandedType);
            }
          });
        }

        if (newValue === compositeType) {
          subtypeObj = this.addCompositeRow();
          values.push(subtypeObj);
          // IF: push the "subtype" + rate_value
          // ELSE: Push subtypeObj to table row and values object
          if (this.isStacked) {
            this.tableRows.push({
              id: compositeType,
              name: compositeType,
              rateValueType: compositeType,
              rateValue: { values },
            });
          } else {
            this.tableRows.push([subtypeObj]);
          }
        } else if (newValue === ageBandedType) {
          this.maxLength = this.ageBanded.length;
          // Loop through all of the age banded values to create rows
          for (i; i < this.maxLength; i += 1) {
            subtypeObj = this.addAgeBandedRow(i);
            this.tableRows.push([subtypeObj]);
            values.push(subtypeObj);
          }
        } else {
          if (this.isStopLoss) {
            subtypeObj = this.addCompositeRow();
            values.push(subtypeObj);
          } else {
            // Create age banded values array from each age band
            for (i; i < this.maxLength; i += 1) {
              subtypeObj = this.addAgeBandedRow(i); // AgeBandedRateValue is default
              values.push(subtypeObj);
            }
          }

          // push values + subtype id to rowValues for each subtype
          this.tierGroupInfo.tier_subtypes.forEach((subtype) => {
            rowValues.push({
              id: subtype.id,
              values,
            });
            this.setRateAttributeRateValues({
              rateValueType: this.isStopLoss ? compositeType : ageBandedType,
              storeAttributeId: this.storeAttributeId,
              subtypeId: subtype.id,
              values,
            });

            // Add subtype object to table rows for stacked views
            if (this.isStacked) {
              this.tableRows.push({
                id: subtype.id,
                name: subtype.name,
                rateValueType: this.isStopLoss ? compositeType : ageBandedType,
                rateValue: { values },
              });
            }
          });

          if (!this.isStacked) {
            // Push cell groupings to tableRows
            for (let j = 0; j <= this.maxLength - 1; j += 1) {
              const rows = [];

              // Loop through each subtype
              rowValues.forEach((subtypeValues, idx) => {
                // set row group to the to value or empty object
                rows[idx] = subtypeValues.values[j];
              });
              this.tableRows.push(rows);
            }
          }
        }
        const newTierGroup = this.rateTierGroups
          .find((tierGroup) => tierGroup.tier_group_name === newValue);

        // Age banded and composite have only a single Rate Type
        if ([ageBandedType, compositeType, customType].includes(newValue)) {
          // Update pinia store
          this.setRateAttributeRateValues({
            rateValueType: newValue,
            storeAttributeId: this.storeAttributeId,
            subtypeId: this.attributeType,
            values,
          });
        }
        this.loading = false;
        this.updateAttributes({
          productId: this.productId,
          productState: this.productState,
          storeAttributeId: this.storeAttributeId,
          subtypeId: newTierGroup?.tier_group_id || this.attributeType,
        });
      },
    },
    /**
     * create rows for attribute table when component is created
     */
    async created() {
      if (this.tierGroup) {
        await this.getRateEntry({ productId: this.productId, rollOutIds: this.separatedClassIds });
        this.createRows();
      }
      this.$nextTick(this.getInvalidFields);
    },
    methods: {
      ...mapActions(useRateEntryStore, [
        'clearRateAttributeRateValues',
        'setRateAttributeRateValues',
        'updateRateAttribute',
        'getRateEntry',
      ]),
      /**
       * When the user updates a rate value we need to potentially sync rateErrors
       */
      validationTriggered() {
        this.rateErrors.splice(0, this.rateErrors.length);
      },
      /**
       * Configure the rate entry ElForm instance to use validation.
       * Populate rateErrors in the store with a list of component refs
       */
      getInvalidFields() {
        // rff: uploadRenewalUploadEnhancements
        if (!this.uploadRenewalUploadEnhancementsEnabled
          || this.isProductNotStartedOrNotSubmitted) {
          return;
        }

        /**
         * Populate/sync rateErrors with the list of ElFormItem elements with validation errors
         */
        this.$refs.form.validate((valid, invalid) => {
          const existingErrorFields = this.rateErrors.map((field) => field());
          /**
           * After we validate the form we refresh rateError with invalid fields without wiping out items from other forms
           */
          const invalidFields = this.$refs.form.fields.filter(
            ({ prop }) => Object.keys(invalid).includes(prop),
          ).map(({ $el }) => $el.querySelector('input, textarea'));
          const formFields = this.$refs.form.fields.map(({ $el }) => $el.querySelector('input, textarea'));

          if (!invalidFields.every((field) => existingErrorFields.includes(field))) {
            const updatedErrors = this.rateErrors.filter((field) => !formFields.includes(field()));

            this.rateErrors.splice(
              0,
              this.rateErrors.length,
              ...updatedErrors,
              ...invalidFields.map((field) => () => field),
            );
          }
        });
      },
      /**
       * Method to create the rows when the page is first loaded.
       */
      createRows() {
        const rowValues = []; // Used to create each cell grouping in the row
        let id;
        let subtypeValue;
        let values;

        this.loading = true;
        // Set Tier Group Info and layout based on attribute type
        this.updateTierGroupInfo('createRows');

        if (this.isComposite) {
          this.tierSubtypes.push({
            id: compositeType,
            name: compositeType,
          });
          if (this.isStacked) {
            this.tableRows.push({
              id: compositeType,
              name: compositeType,
              rateValueType: compositeType,
              rateValue: this.tierGroup.tier_subtypes[0].rate_value,
            });
          } else {
            this.tableRows.push([
              this.isPlan
                ? this.tierGroup.tier_subtypes[0].rate_value.values[0]
                : this.tierGroup.tier_subtypes[0].rate_values[0].values[0],
            ]);
          }
          this.loading = false;
        } else {
          this.tierGroup.tier_subtypes.forEach((subtype, idx) => {
            if (this.isPlan) {
              subtypeValue = subtype.rate_value;
              id = subtypeValue.id;
              values = subtypeValue.values;
            } else {
              [subtypeValue] = subtype.rate_values;
              id = subtypeValue.id;
              values = subtypeValue.values;
            }

            // IF: add all values for each `tier subtype`
            // ELSE: replace maxLength of table rows if greater than previous `tier subtype`
            if (this.isStacked) {
              this.maxLength += values.length;
            } else {
              this.maxLength = values.length > this.maxLength
                ? values.length
                : this.maxLength;
            }
            // Push subtype option to this.subtypeOptions (only if there are subtype)
            if (this.tierGroup.tier_subtypes.length > 1) {
              this.tierSubtypes.push({
                id: subtype.id,
                name: subtype.name,
              });
              // Stack options only need one the subtype option
              const existingOption = this.isStacked
                && !this.subtypeOptions.includes(subtypeValue.type);

              // Push options if not stacked or stacked && !exists
              if (!this.isStacked || existingOption) {
                this.subtypeOptions.push(subtypeValue.type);
              }
            } else {
              this.tierSubtypes.push({
                id: this.attributeType,
                name: this.attributeType,
              });
            }

            // IF: Push modified subtype object to tableRows
            // ELSE: Store values of each subtype to loop through later
            if (this.isStacked) {
              this.tableRows.push({
                id: subtype.id,
                name: subtype.name,
                rateValue: this.isPlan
                  ? subtype.rate_value
                  : subtype.rate_values[0],
              });
            } else {
              rowValues.push({ id: (id || idx), values });
            }
          });

          if (this.isStacked) {
            this.loading = false;

            return;
          }

          // Loop through max length to create cell groups
          for (let i = 0; i <= this.maxLength - 1; i += 1) {
            const rows = [];

            // Loop through each subtype
            rowValues.forEach((subtype, idx) => {
              rows[idx] = subtype.values[i] || {};
            });
            this.tableRows.push(rows);
          }
          this.loading = false;
        }
      },
      /**
       * Method used to add a composite cell group.
       *
       * @returns {object}
       */
      addCompositeRow() {
        return {
          comparison_flag: 'deviation_detected',
          display_label: 'Composite',
          label: 'composite',
          value: null,
          volume: null,
        };
      },
      /**
       * Method used to add a age banded cell group
       * Pass the `idx` to create the appropriate age label.
       *
       * @param {number} idx
       * @returns {object}
       */
      addAgeBandedRow(idx) {
        return {
          comparison_flag: 'deviation_detected',
          display_label: this.ageBanded[idx] === 'age_80_plus'
            ? '80+'
            : this.ageBanded[idx].replace('age_', '').replace('_', '-'),
          label: this.ageBanded[idx],
          value: null,
          volume: null,
        };
      },
      /**
       * Method used when updating the subtier type from the table header dropdown.
       *
       * @param {object} root0
       * @param {number} root0.id
       * @param {number} root0.cellGroupIdx
       * @param {string} root0.rateValueType
       */
      updateSubtypeData({ id, cellGroupIdx, rateValueType }) {
        const maxRowLength = this.subtypeOptions.includes(ageBandedType)
          ? this.ageBanded.length
          : 1;
        const values = [];
        let i = 0;
        let subtypeObj;

        // If both subtypes are set to composite, clear out 1+ row indexes
        if (maxRowLength === 1) {
          this.tableRows = [this.tableRows[0]];
        }

        for (i; i < maxRowLength; i += 1) {
          //  Need to create table row if it doesn't exist yet for indexs > 1
          if (!this.tableRows[i] && i > 0) {
            this.tableRows.push(this.tierGroup.tier_subtypes.map(() => ({})));
          }

          if (rateValueType === compositeType) {
            // Update 0 array cellGroupIdx to composite && empty/{} for everything else in the cells
            subtypeObj = !i ? this.addCompositeRow() : {};
          } else {
            subtypeObj = this.addAgeBandedRow(i);
          }

          // The case for this is if you have a combination of composite/age-banded rows,
          // We're pushing an empty object to the row (for layout)
          // But we don't want to push empty objects to the store.
          if (Object.keys(subtypeObj).length) {
            values.push(subtypeObj);
          }

          // Update row to subtypeObj
          this.tableRows[i][cellGroupIdx] = subtypeObj;
        }
        // Update pinia store
        this.setRateAttributeRateValues({
          rateValueType,
          storeAttributeId: this.storeAttributeId,
          subtypeId: id,
          values,
        });
      },

      /**
       * Method used when updating the subtier type from the table header dropdown.
       *
       * @param {string} trigger
       */
      updateTierGroupInfo(trigger) {
        this.staleTimers = true;
        let isStacked = false;
        let tierGroupInfo = {};

        if (![ageBandedType, compositeType, customType].includes(this.attributeType)) {
          const updatedTierGroup = this.rateTierGroups
            .find((tierGroup) => tierGroup.tier_group_name === this.attributeType);

          if (!updatedTierGroup) {
            addBreadcrumb({
              category: 'method',
              data: {
                attributeType: this.attributeType,
                rateTierGroups: this.rateTierGroups,
                text: 'missing updatedTierGroup',
                trigger,
              },
              level: 'debug',
            });
            this.error = true;
          } else {
            const {
              tier_group_id: id,
              tier_group_layout: layout,
              tier_group_name: name,
              tier_subtypes: subtypes,
            } = updatedTierGroup;

            tierGroupInfo = {
              id,
              layout,
              name,
              /* eslint-disable no-shadow */
              tier_subtypes: subtypes.map(({
                subtype_id: id,
                subtype_name: name,
              }) => ({
                id,
                name,
              })),
            };
            /* eslint-enable no-shadow */
            isStacked = layout === 'stacked';
          }

          this.tierGroupInfo = tierGroupInfo;
          this.isStacked = isStacked;
        }
      },
      /**
       * Debounce the patch calls made to the API so that changes made to multiple inputs
       * in quick succession don't get fired to the API every time. We only need the latest
       * change made in a given value column to be sent to the API.
       *
       * @param {object} newAttributes
       */
      updateAttributes(newAttributes) {
        // since any potential patch to an old tiergroupid would be moot, we need to delete
        // all currently running debounce timers when the timers have gone stale
        if (this.staleTimers) {
          this.staleTimers = false;
          Object.keys(this.debounceTimers).forEach((timer) => {
            clearTimeout(timer);
          });
          this.debounceTimers = {};
        }

        if (this.debounceTimers[newAttributes.subtypeId]) {
          clearTimeout(this.debounceTimers[newAttributes.subtypeId]);
        }
        this.$set(
          this.debounceTimers,
          newAttributes.subtypeId,
          setTimeout(async () => {
            this.$delete(this.debounceTimers, newAttributes.subtypeId);
            await this.updateRateAttribute(newAttributes);
            this.validationTriggered();
          }, 400),
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  margin-bottom: 20px;
}

.logo {
  display: block;
  height: 60px;
  margin: 0 auto;
}

.rate-table {
  flex: 1;
  flex-direction: column;

  &:first-child {
    margin-right: 20px;

    .dialog-plan-summary & {
      margin-right: 0;
    }
  }
}

.title {
  padding: {
    left: 6px;
    right: 6px;
  }
  background: $tf-extra-light-gray-2;

  .stacked & {
    text-align: left;
    padding: 0 20px;
    white-space: nowrap;
  }
}

.proposed-title {
  font-weight: bold;
}

.el-alert {
  margin-bottom: 20px;
}

tbody, th {
  border: 1px solid $tf-extra-light-gray-1;
}

h5 {
  display: flex;
  align-items: center;
  height: 80px;
  margin-bottom: 0;
  border: 1px solid $tf-extra-light-gray-1;
}

td {
  vertical-align: top;
}
</style>
