// eslint-disable-next-line import/no-named-default
import Vue from 'vue';
import { defineStore } from 'pinia';
import { cloneDeep } from 'lodash';
// eslint-disable-next-line import/no-cycle
import { useProductStore } from '@/stores/product.js';
import rateEntryService from '@/services/rateEntry.js';
import planDesignService from '@/services/planDesign.js';

export const useRateEntryStore = defineStore('rateEntry', {
  state: () => ({
    // Store Data
    loadingRateEntry: false,
    rateAttributes: {},
    rateErrors: [],
    info: {},
    isLoaded: false,
    rateGuarantee: null,
    patchingGuarantee: false,
    editingRateAttributeValue: false,
    rateEntryContainers: [],
    validRateEntry: false,
    editingRateBasis: false,
    classesAreSeparated: false,
    separatedClassIds: [],
  }),
  getters: {
    // eslint-disable-next-line arrow-body-style
    isRateEntryValid: (state) => Object.keys(state.rateAttributes || {})
      .every((attributeId) => Object.keys(state.rateAttributes[attributeId].rateValues)
        .every((subtypeId) => state.rateAttributes[attributeId].rateValues[subtypeId].values
          .every((value) => value.value || value.value === 0)))
      && (useProductStore().isStopLoss || (!useProductStore().isStopLoss && !!state.rateGuarantee?.value)),
  },
  actions: {
    setRateAttributes() {
      const rateAttributes = {};

      this.rateEntryContainers.forEach(({ id: containerId, rateAttributes: rateAttrs }) => {
        let proposalValues;
        let policyValues;

        rateAttrs.forEach(({ id: attributeId, products }) => {
          policyValues = products.find(({ document_type: docType }) => docType.toLowerCase() === 'policy');
          proposalValues = products.find(({ document_type: docType }) => docType.toLowerCase() !== 'policy');

          const attributeType = proposalValues.tier_group?.name;
          let policyAttributeType;
          let rateValue;
          const rateValues = {};
          let value;
          const isPlan = this.info.container_type.toLowerCase() === 'plan';

          if (isPlan) {
            policyAttributeType = policyValues.tier_group.name
              || policyValues.tier_group.tier_subtypes[0].rate_value.type;
            rateValue = proposalValues.tier_group?.tier_subtypes[0].rate_value;
          } else {
            policyAttributeType = policyValues.tier_group.name
              || policyValues.tier_group.tier_subtypes[0].rate_values[0].type;
            rateValue = proposalValues.tier_group?.tier_subtypes[0].rate_values[0] || null;
          }

          proposalValues.tier_group.tier_subtypes.forEach((subtype) => {
            value = isPlan ? subtype.rate_value : subtype.rate_values[0];
            // Set `rateValues` key to composite/age-banded since there is no id associated with these
            // Otherwise set it to the subtypeId
            if (!subtype.id) {
              rateValues[value.type] = {
                type: value.type,
                values: value.values,
              };
            } else {
              rateValues[subtype.id] = {
                type: value.type,
                values: value.values,
              };
            }
          });

          const newAttribute = {
            attributeId,
            attributeType: attributeType || rateValue?.type,
            containerIds: containerId,
            originalTierGroupId: proposalValues.tier_group?.id,
            policyAttributeType,
            rateBasis: rateValue?.rate_basis,
            rateValues,
            tierGroupId: proposalValues.tier_group?.id || null,
          };
          const attrId = Array.isArray(containerId) ? `${containerId.join('_')}` : containerId;

          rateAttributes[`${attrId}_${attributeId}`] = newAttribute;
        });
      });

      Vue.set(this, 'rateAttributes', rateAttributes);
    },

    // Store Actions
    /**
     * Unroll a rolled up rate entry container and create separate containers for them.
     *
     * @param {Array} rolledContainerIds - The array of container ids inside of the rolled up container.
     * @param {Array} containers - the array of rate entry containers.
     * @param {Array} containerInfos - An array of rate entry info objects grouped by container.
     */
    unrollContainer(rolledContainerIds, containers, containerInfos) {
      const rolledContainerIndex = containers.findIndex(
        ({ id }) => id.join('') === rolledContainerIds.join(''),
      );
      const containerToUnroll = cloneDeep(containers.splice(rolledContainerIndex, 1)[0]);

      containerToUnroll.id.forEach((id) => {
        const clonedRateAttributes = cloneDeep(containerToUnroll.rateAttributes);
        const containerIndex = containers.findIndex(({ id: rolledIds }) => rolledIds.join('') === id.toString());

        if (containerIndex === -1) {
          const containerInfo = containerInfos.find(({ id: infoId }) => infoId === id);

          containers.push({
            containerNames: [containerInfo.name],
            rateAttributes: clonedRateAttributes,
            description: [containerInfo.description],
            id: [id],
            name: `Class ${containerInfo.name}`,
          });
        } else {
          clonedRateAttributes.forEach((attribute) => {
            containers[containerIndex].rateAttributes.push(cloneDeep(attribute));
          });
        }
      });
    },
    /**
     * Parse rate entry containers for Class type containers. Unroll a rolled container if
     * a rolled container's array of ids is provided.
     *
     * @param {object} rateEntry - the rate entry response to parse containers from.
     * @param {Array} rolledContainerIds - (optional) The array of container ids inside of a rolled up container.
     */
    setClassTypeContainers(rateEntry, rolledContainerIds = []) {
      const containers = rateEntry.groupings.map((container) => ({
        containerNames: container.container_names, // used for sorting in case there is a roll out later
        description: container.container_ids.map((id) => rateEntry.container_info
          .find((containerInfo) => containerInfo.id === id).description),
        id: container.container_ids,
        name: container.label,
        rateAttributes: container.rate_attributes,
      }));

      if (rolledContainerIds.length) {
        this.unrollContainer(rolledContainerIds, containers, rateEntry.container_info);
      }

      containers.sort((a, b) => {
        let order = 0;
        const nameA = Number(a.containerNames[0]);
        const nameB = Number(b.containerNames[0]);

        if (nameA < nameB) {
          order = -1;
        } else if (nameA > nameB) {
          order = 1;
        }

        return order;
      });

      this.rateEntryContainers = containers;

      this.setRateAttributes();
    },

    /**
     * Parse rate entry containers for Plan type containers.
     *
     * @param {object} rateEntry - the rate entry response to parse containers from.
     */
    setPlanTypeContainers(rateEntry) {
      const containers = rateEntry.containers.map((container) => {
        const containerInfo = rateEntry.container_info
          .find((containerClass) => containerClass.id === container.id);
        const selectedTierGroup = container.available_plan_design_tier_groups
          .find((tierGroup) => tierGroup.selected);
        const type = containerInfo.container_type_name;

        // For plan-based products, we're using the container.description as the "name" and the container.container_type_name as the "description".
        return {
          description: [type], // see above description
          id: [container.id],
          name: containerInfo.description || type, // see above description
          rateAttributes: container.rate_attributes,
          tierGroups: container.available_plan_design_tier_groups,
          // ATM dental is the only Plan Based product that will come back with a selected tier group 100% of the time
          tierGroupId: selectedTierGroup ? selectedTierGroup.id : null,
          type: type.toLowerCase(),
        };
      });

      this.rateEntryContainers = containers;

      this.setRateAttributes();
    },

    /**
     * Sets the rate entry containers, info, and rate guarantee properties for the store and
     * sets the loadingRateEntry property to false once finished.
     *
     * @param {object} rateEntry
     * @param {Array} rolledContainerIds
     */
    setRateEntry(rateEntry, rolledContainerIds) {
      this.info = rateEntry;
      this.rateGuarantee = rateEntry.rate_guarantee;

      if (rateEntry.container_type === 'Class') {
        const ids = this.classesAreSeparated ? this.separatedClassIds : rolledContainerIds;

        this.setClassTypeContainers(rateEntry, ids);
      } else {
        this.setPlanTypeContainers(rateEntry);
      }

      this.loadingRateEntry = false;
    },

    /**
     * Copy all the rate values from the incumbent product to the product being edited.
     *
     * @param {number} productId
     * @param {Array<number>} containerIds
     * @param {string} productState
     */
    async copyInforceRate(productId, containerIds, productState) {
      this.loadingRateEntry = true;
      this.rateEntryContainers = [];

      // Only patch the product state if it isn't currently in any in progress state.
      if (['not_started', 'completed'].includes(productState)) {
        try {
          const endpointState = productState === 'completed' ? 'edit' : 'start';

          await useProductStore().updateProductState({ endpointState, productId });
        } catch {
          throw new Error('An error occurred when attempting to update the product state.');
        }
      }

      try {
        const {
          project_product: rateEntry,
        } = await rateEntryService.copyRatesFromInforce(productId, containerIds);

        this.setRateEntry(rateEntry);
        this.rateErrors.splice(0, this.rateErrors.length);
      } catch {
        throw new Error('An error occurred when attempting to copy rates from inforce.');
      }
    },

    /**
     * Clear the rate values for a given rate attribute and rate value type.
     *
     * @param {object} root
     * @param {number} root.rateValueType
     * @param {number} root.storeAttributeId
     */
    clearRateAttributeRateValues({ rateValueType, storeAttributeId }) {
      Vue.delete(this.rateAttributes[storeAttributeId].rateValues, [rateValueType]);
    },

    /**
     * Get rate entry from API
     *
     * @param {object} root
     * @param {number|null} root.productId
     * @param {Array} root.rollOutIds
     * @returns {Promise}
     */
    async getRateEntry({ productId, rollOutIds = [] }) {
      this.loadingRateEntry = true;

      const { project_product: rateEntry } = await rateEntryService.getRateEntry(productId);

      this.setRateEntry(rateEntry, rollOutIds);

      this.isLoaded = true;
      this.loadingRateEntry = false;
    },

    /**
     * Resets the rate entry info object and rate entry containers array.
     */
    clearRateEntry() {
      this.info = {};
      this.rateEntryContainers = [];
    },

    /**
     * Updates rate fields in the Rate Entry tab, and sends the updated values to the API.
     *
     * @param {object} params
     * @param {string} params.productId
     * @param {string} params.productState
     * @param {string} params.storeAttributeId
     * @param {string} params.subtypeId
     * @param {boolean} [params.skipDelete=false]
     * @returns {Promise<undefined|Function>} Returns a promise that resolves to a function if the tier group ID has not changed or undefined if it has.
     */
    async updateRateAttribute({
      productId,
      productState,
      storeAttributeId,
      subtypeId,
      skipDelete = false,
    }) {
      const rateAttribute = this.rateAttributes[storeAttributeId];

      // Test for null values
      if (!subtypeId || !rateAttribute || !productId) {
        return undefined;
      }

      const patchRateAttribute = async (id) => {
        // We need to patch product states.

        await rateEntryService.patchRateAttribute({
          productId,
          rateAttribute,
          subtypeId: id,
        });

        if (productId) {
          const endpointState = productState === 'completed' ? 'edit' : 'start';

          await useProductStore().updateProductState({ endpointState, productId });
        }

        this.editingRateAttributeValue = false;
      };

      // If Tier Group has changed IDs, the values must first be DELETED, then patched
      if (
        !skipDelete
        && rateAttribute.tierGroupId !== rateAttribute.originalTierGroupId
      ) {
        // Store the original tier group ID in case we need to revert it on failure.
        const { originalTierGroupId } = rateAttribute;

        // Set the new "original Tier Group ID" to prevent additional deletion attempts
        this.rateAttributes[storeAttributeId].originalTierGroupId = rateAttribute.tierGroupId;

        try {
          await rateEntryService.deleteRateAttributeValues({ productId, rateAttribute });
        } catch (error) {
          this.rateAttributes[storeAttributeId].originalTierGroupId = originalTierGroupId;

          throw new Error(`An error occurred when attempting to delete rate attribute values. Error: ${error.message}`);
        }
      }

      try {
        // When changing a tier group, we need to patch values for each subtype.
        // This is required if a user doesn't fill out all the subtype values and leaves the page.

        // IF: the first/only rate attribute rate values is composite, age, or custom, single patch.
        // ELSE: Loop through each rate value and patch subtype IDs
        if (['CompositeRateValue', 'AgeBandedRateValue', 'CustomRateValue'].includes(subtypeId)) {
          await patchRateAttribute(subtypeId);
        } else {
          Object.keys(rateAttribute.rateValues).forEach(async (id) => {
            if (!Number.isNaN(parseInt(id, 10))) {
              await patchRateAttribute(parseInt(id, 10));
            }
          });
        }
      } catch (error) {
        throw new Error(`An error occurred when attempting to patch rate attributes. Error: ${error.message}`);
      }

      return undefined;
    },

    /**
     * Asynchronously patches the rate guarantee of a product.
     *
     * @param {object} params
     * @param {string} params.productId
     * @param {string} params.productState
     * @returns {undefined} Does not return a value.
     */
    async patchRateGuarantee({ productId, productState }) {
      if (!this.rateGuarantee || !productId) {
        return;
      }

      this.patchingGuarantee = true;

      // Change validPlanDesign to false since Plan Design will need to be validated again.
      if (this.validRateEntry) {
        this.validRateEntry = false;
      }

      const patchPlanAttribute = async () => {
        await planDesignService.patchPlanAttribute({
          plan_design_attribute_id: this.rateGuarantee.plan_design_attribute_id,
          product_id: productId,
          project_products_container_ids: this.info.container_info.map((container) => container.id),
          tier_group_id: this.rateGuarantee.tier_group_id,
          tier_subtype_id: this.rateGuarantee.tier_subtype_id,
          value: this.rateGuarantee.value,
        }).then(() => {
          this.patchingGuarantee = false;
        });
      };
      const endpointState = productState === 'completed' ? 'edit' : 'start';

      await patchPlanAttribute();
      await useProductStore().updateProductState({ endpointState, productId });
    },

    /**
     * Asynchronously patches the tier group of a plan design value.
     *
     * @param {object} params
     * @param {string} params.containerId
     * @param {string} params.productId
     * @param {string} params.tierGroupId
     * @returns {object} The response from the plan design service.
     */
    async patchRateEntryPlanValueTierGroup({ containerId, productId, tierGroupId }) {
      this.loadingRateEntry = true;

      try {
        const response = await planDesignService.patchPlanDesignValueTierGroup({
          containerId: containerId[0],
          productId,
          tierGroupId,
        });

        this.rateEntryContainers.find((container) => container.id === containerId).tierGroupId = tierGroupId;

        this.loadingRateEntry = false;

        return response;
      } catch (error) {
        this.loadingRateEntry = false;
        throw error;
      }
    },

    /**
     * Sets the rate values for a specific rate attribute.
     *
     * @param {object} params
     * @param {string} params.rateValueType
     * @param {string} params.storeAttributeId
     * @param {string} params.subtypeId
     * @param {Array} params.values
     */
    setRateAttributeRateValues({
      rateValueType,
      storeAttributeId,
      subtypeId,
      values,
    }) {
      const newRateAttributeValues = {
        type: rateValueType,
        values: cloneDeep(values),
      };

      Vue.set(
        this.rateAttributes[storeAttributeId].rateValues,
        [subtypeId],
        newRateAttributeValues,
      );
    },

    /**
     * Hits the API to validate the rate structure of a product by its ID.
     *
     * @param {string} productId
     * @returns {boolean|undefined} Returns the validation status of the product's rate structure, or undefined if the product ID is not provided.
     */
    async validateRateEntry(productId) {
      if (!productId) {
        return undefined;
      }

      try {
        const { product } = await rateEntryService.validateRateEntry(productId);

        this.validRateEntry = product.valid_rate_structure;

        useProductStore().updateProduct(product);

        return product.valid_rate_structure;
      } catch (error) {
        if (error.data.product) {
          this.validRateEntry = error.data.product.valid_rate_structure;
        }
        throw error;
      }
    },

    /**
     * Validate the rate guarantee value
     *
     * @param {string} productId
     * @returns {boolean|undefined} validation status
     */
    async validateRateGuarantee(productId) {
      if (!productId) {
        return undefined;
      }

      const { product } = await rateEntryService.validateRateGuarantee(productId);

      useProductStore().updateProduct(product);

      return product.valid_rate_guarantee;
    },
  },
});
