<template>
  <div
    v-loading="loading || fauxLoading"
    class="data-entry-landing"
  >
    <template v-if="isInfoLoaded">
      <div
        class="data-entry-containers"
        data-test="entry container"
      >
        <container
          v-for="(container, containerIndex) in containers"
          :key="container.id.join('_')"
          :container="container"
          :warning-message="
            !reviewAlertDismissed
              && !containerIndex
              && warningMessage ? warningMessage : null"
          @dismissAlert="reviewAlertDismissed = true"
        />
        <div
          v-if="!loading && !isStopLoss"
          id="rate-guarantee-container"
        >
          <div>
            <h3>Rate guarantee</h3>
            <ElForm
              ref="form"
              label-width="120px"
              label-position="left"
            >
              <ElFormItem
                label="# of months"
                size="medium"
              >
                <TfInputWithOptions
                  v-model="rateGuarantee"
                  v-bind="{
                    allowEnter: false,
                    disabled: $route.meta.readonly,
                    options: rateEntry.rate_guarantee
                      ? rateEntry.rate_guarantee.normalized_values
                      : [],
                    prop: 'rate guarantee'
                  }"
                  placeholder="Enter guarantee"
                  validation-type="whole-number"
                  data-test="guarantee field"
                  @change="updateRateGuarantee({ value: $event, save: true })"
                />
              </ElFormItem>
            </ElForm>
          </div>
        </div>
      </div>
      <footer :class="{ 'review-footer' : $route.meta.readonly }">
        <div>
          <RouterLink
            id="link-return-to-plan"
            :to="{
              name: $route.meta.readonly ? 'PlanDesignReview' : 'PlanDesign'
            }"
          >
            <AppButton
              icon="fa-solid fa-chevron-left"
              size="text"
              text="Back to plan design"
              prepend
              data-test="to plan design"
            />
          </RouterLink>
          <div class="btn-group">
            <AppButton
              v-if="!$route.meta.readonly"
              :is-loading="validatingProduct"
              type="secondary"
              text="Finish later"
              @click="onFinishLaterClick"
            />
            <AppButton
              v-if="$route.meta.readonly"
              icon="check"
              text="Done"
              @click="$router.push({ name: 'RfpOverview' })"
            />
            <AppButton
              v-else
              id="btn-submit-quote"
              :is-disabled="!canSubmit || validatingProduct"
              :is-loading="validatingProduct"
              icon="fa-solid fa-pencil"
              data-test="submit quote"
              text="Submit quote"
              @click="onContinue"
            />
          </div>
          <span
            v-if="!$route.meta.readonly"
            id="terms-disclaimer"
            data-test="terms disclaimer"
          >
            By submitting, you agree to our&nbsp;
            <AppButton
              size="text"
              text="Terms"
              data-test="to terms"
              @click="showTermsModal = !showTermsModal"
            />
            .
          </span>
        </div>
        <p
          v-if="errorOnSubmit"
          class="text-error"
        >
          {{ errorOnSubmit }}
        </p>
      </footer>
    </template>
    <TermsModal
      v-if="showTermsModal"
      :visible.sync="showTermsModal"
      data-test="terms modal"
      @closeDialog="showTermsModal = false"
    />
  </div>
</template>

<script>
  import { mapState, mapActions, mapWritableState } from 'pinia';
  import { ReusableData } from '@watchtowerbenefits/shared-components';
  import { useCarrierInfoStore } from '@/stores/carrierInfo.js';
  import { useNotificationsStore } from '@/stores/notifications.js';
  import { useRateEntryStore } from '@/stores/rateEntry.js';
  import { useQuoteEditsStore } from '@/stores/quoteEdits.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useProductStore } from '@/stores/product.js';
  import { useAccountStore } from '@/stores/account.js';
  import { usePlanDesignStore } from '@/stores/planDesign.js';
  import Container from '@/components/RateEntry/RateEntryContainer.vue';
  import TermsModal from '@/components/Modals/TermsModal.vue';
  import { segmentData } from '@/utils/analytics.js';
  import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';

  /**
   * Rate Entry for the product page.
   *
   * @exports ProductLanding/RateEntryLanding
   */
  export default {
    name: 'RateEntryLanding',
    components: {
      Container,
      TermsModal,
    },
    data: () => ({
      destroyProductId: null,
      displayAlertReview: false,
      errorOnSubmit: false,
      fauxLoading: false,
      isInitRateGuarantee: true,
      showTermsModal: false,
      timer: 0,
      validatingProduct: false,
      warningMessage: null,
    }),
    computed: {
      ...mapWritableState(useNotificationsStore, ['reviewAlertDismissed']),
      ...mapWritableState(useRateEntryStore, {
        rateEntryRateGuarantee: 'rateGuarantee',
        validRateEntry: 'validRateEntry',
        editingRateAttributeValue: 'editingRateAttributeValue',

      }),
      ...mapState(useCarrierInfoStore, { carrierId: 'id', carrierName: 'name' }),
      ...mapState(useRateEntryStore, {
        rateAttributes: 'rateAttributes',
        loading: 'loadingRateEntry',
        rateEntry: 'info',
        isInfoLoaded: 'isLoaded',
        containers: 'rateEntryContainers',
        patchingGuarantee: 'patchingGuarantee',
      }),
      ...mapState(useProjectStore, ['project', 'projectId', 'proposalDocumentId']),
      ...mapState(useProductStore, [
        'currentProduct',
        'isStopLoss',
        'productId',
        'productState',
        'currentProductPreviouslySubmitted',
      ]),
      ...mapState(useAccountStore, ['rateProductsVisited']),
      ...mapState(usePlanDesignStore, ['validPlanDesign']),
      /**
       * Returns a boolean to determine if the continue button is enabled based on if all proposal inputs.
       * For stop loss products, we ignore the rateGuarantee.
       *
       * @returns {boolean}
       */
      canSubmit() {
        if (this.loading) {
          return false;
        }

        return this.currentProduct && !this.isStopLoss
          ? Boolean(!Object.values(this.rateValues).includes(null) && this.rateGuarantee)
          : !Object.values(this.rateValues).includes(null);
      },
      rateGuarantee: {
        /**
         * For stop loss products, we ignore the rateGuarantee.
         * Mapped to the rateEntry.rateGuarantee.
         *
         * @returns {string}
         */
        get() {
          return this.currentProduct && !this.isStopLoss
            ? this.rateEntryRateGuarantee.value
            : null;
        },
        /**
         * Fires `patchRateGuarantee` action to update the rate guarantee in the store.
         *
         * @param {string} value
         */
        set(value) {
          // Value is getting set from the shared component on load.
          if (this.isInitRateGuarantee) {
            return;
          }
          this.updateRateGuarantee({ value });
        },
      },

      /**
       * Dynamic array of all the values to test if continue button is disabled.
       *
       * @returns {Array}
       */
      rateValues() {
        if (this.loading) {
          return undefined;
        }
        const rateValues = {};

        Object.keys(this.rateAttributes).forEach((attributeId) => {
          Object.keys(this.rateAttributes[attributeId].rateValues).forEach((subtypeId) => {
            this.rateAttributes[attributeId].rateValues[subtypeId].values.forEach((value) => {
              rateValues[`${attributeId}_${subtypeId}_${value.label}`] = value.value === 0
                ? 0
                : value.value || null; // empty strings should be converted to null
            });
          });
        });

        return Object.values(rateValues);
      },
    },

    /**
     * get rate entry
     * set any warning messages
     * set destroyProductId
     */
    created() {
      const rateVisited = this.rateProductsVisited
        .includes(this.currentProduct.id);

      // Get Rate Entry
      this.getRateEntry({ productId: this.productId });

      // If the product is in a completed state and they haven't dismissed the alert.
      if (this.currentProduct.state === 'completed' && !this.reviewAlertDismissed) {
        this.displayAlertReview = true;
        this.warningMessage = 'Making changes to your entries will remove your original quote from the broker’s view. Your quote will become visible to the broker again once you submit your updates.';
      }

      // If we should display a non-stop loss plan alternative alert based on if the first time on this products rate page and the product hasn't been submitted.
      if (
        !rateVisited
        && Boolean(this.currentProduct)
        && !this.currentProductPreviouslySubmitted
        && this.currentProduct.project_product.alternative
        && !this.isStopLoss
      ) {
        this.warningMessage = 'This is a plan alternative. To get you started, we’ve set up the rate options based on the broker’s request.';
      }

      // destroyProductId needs to be set as the productId to be used in the beforeDestroy lifecycle.
      this.destroyProductId = this.productId;

      this.setProductsFiltersSelected([+this.$route.params.productId]);
    },

    /**
     * validate rate entry then clear it
     */
    async beforeDestroy() {
      // Validate the Rate Entry on destroy instead of covering every page exit
      // Only do this when clicking on continue or if they've filled out the form
      // and then left the page w/o clicking continue. This is sort of edge casey but want to cover it.
      if (this.canSubmit && !this.validRateEntry) {
        try {
          await this.validateRateEntry(this.destroyProductId);
        } catch (e) {
        // We are ignoring any error state here b/c we don't want to show anything
        // But we want to avoid uncaught promises
        }
      }
      // Clear all the Rate Entry things
      this.clearRateEntry();

      // If a user leaves any rate entry values blank before moving back to plan design, we want to
      // make sure the edit state is reset back to false as an empty value leaves the edit state as true
      this.editingRateAttributeValue = false;
    },

    methods: {
      ...mapActions(useQuoteEditsStore, ['setProductsFiltersSelected']),
      ...mapActions(useRateEntryStore, [
        'getRateEntry',
        'clearRateEntry',
        'validateRateEntry',
        'patchRateGuarantee',
      ]),
      ...mapActions(useProductStore, ['validateProduct', 'submitQuote']),
      /**
       * We need to pulse until the rate guarantee has patched successfully
       * If the Rate Guarantee is still getting patched, then we put up a fake loader until done.
       */
      onContinue() {
        const checkPatching = () => {
          setTimeout(() => {
            if (this.patchingGuarantee || this.editingRateAttributeValue || !this.canSubmit) {
              this.fauxLoading = true;
              checkPatching();
            } else {
              this.handleSubmitQuote();
            }
          }, 200);
        };

        checkPatching();
      },
      /**
       * handle 'Finish Later' button click
       */
      onFinishLaterClick() {
        this.$router.push({ name: 'RfpOverview' });
      },
      /**
       * Validate Both Plan Design && Rate Entry before submitting the form
       */
      async handleSubmitQuote() {
        // disable the submit button to prevent double clicking
        this.validatingProduct = true;
        this.errorOnSubmit = false;

        try {
          // validate plan design and rate entry
          await this.validateProduct(this.productId);
        } catch {
          // Invalid validation is coming back as 400 so we need to catch this to avoid
          // js errors and make the button ready for resubmit or there is no way to continue.
          this.validateError();

          return;
        }

        // Enable the submit button again.
        if (!this.validPlanDesign || !this.validRateEntry) {
          this.validateError();

          return;
        }

        try {
          await this.submitQuote(this.productId);
          const projectType = this.project.type === ReusableData.projectTypes.renewal
            ? 'renewal'
            : 'new market';

          trackSegmentEvent(`Submit ${projectType} quote`, segmentData({
            product_type: this.currentProduct.product_type_name,
            carrier_name: this.carrierName,
            carrier_id: this.carrierId,
            // these values were already being passed before CP-648
            project_id: this.projectId,
            proposal_id: this.proposalDocumentId,
          }));

          this.$router.push({ name: 'RfpOverview' });
        } catch {
          this.validatingProduct = false;
          this.errorOnSubmit = 'Your request did not go through. Please click submit quote to try again.';
        }
      },
      /**
       * Puts page in state to show error and activate Submit button on validation error for resubmit
       */
      validateError() {
        this.validatingProduct = false;
        this.errorOnSubmit = 'Your request did not go through. Please check the Rate Entry values for any errors.';
      },
      /**
       * Updates the store value
       * Saves on blur or if `save` is passes as true.
       *
       * @param {object} root0
       * @param {string} root0.value
       * @param {boolean} root0.save
       */
      updateRateGuarantee({ value, save = false }) {
        // Debounce value updating and save
        clearTimeout(this.timer);

        this.timer = setTimeout(() => {
          this.rateEntryRateGuarantee.value = value;
          this.validRateEntry = false;

          if (save && value) {
            this.isInitRateGuarantee = false;
            this.patchRateGuarantee({
              productId: this.productId,
              productState: this.productState,
            });
          }
        }, 50);
      },
    },
  };
</script>

<style lang="scss" scoped>
footer.review-footer {
  > div {
    &:after {
      content: '';
      flex: 1;
    }
  }
}

#link-return-to-plan {
  display: flex;
  flex: 1;
  justify-content: flex-start;
}

.btn-group {
  flex: 2;
  margin: auto;
  height: 32px;
}

#rate-guarantee-container {
  > div {
    width: 100%;
    max-width: 450px;
    margin: 30px auto;
  }

  h3 {
    margin-bottom: 5px;
  }
}

#terms-disclaimer {
  flex: 1;
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  font-size: 14px;
  margin-right: 70px;
}
</style>
