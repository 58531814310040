<template>
  <td
    :class="{
      'cell-empty' : !cellData.label,
      'cell-show-input' : !readonly && cellData.label }"
    width="25%"
  >
    <ElFormItem
      v-if="!readonly && cellData.label"
      ref="formItem"
      :prop="formProp"
      size="medium"
      label-width="0"
      :show-message="hasUnsavedChanges"
    >
      <ElInput
        v-model="valueModel"
        placeholder="Enter rate"
        data-test="rate field"
        :class="!value ? 'input-blank' : ''"
        @blur="onBlur"
      />
    </ElFormItem>
    <!-- Only show values if there is an associated label ) -->
    <span
      v-else-if="cellData.label"
      data-test="read only rate value"
    >
      {{ cellData.value && !isNaN(cellData.value)
        ? parseFloat(cellData.value).toFixed(3)
        : '&mdash;'
      }}
    </span>
  </td>
</template>

<script>
  import { useRateEntryStore } from '@/stores/rateEntry.js';
  import { useProductStore } from '@/stores/product.js';
  import { mapState, mapWritableState } from 'pinia';

  /**
   * Rate Entry Table Cell Value
   *
   * @exports RateEntry/RateEntryTdValue
   */
  export default {
    name: 'RateEntryTdValue',
    inject: [
      'storeAttributeId',
    ],
    props: {
      /**
       * Cell Data used for cell value information
       */
      cellData: {
        type: Object,
        required: true,
        default: () => {},
      },
      /**
       * Readonly boolean used to determine if the proposal table should be inputs.
       */
      readonly: {
        type: Boolean,
        required: true,
        default: false,
      },
      /**
       * When there are tier groups, subtypeId is used to target the specific value array in the rateAttributes store
       */
      subtypeId: {
        type: [Number, String],
        default: 0,
      },
      /**
       * Form key for validation
       */
      formKey: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        hasUnsavedChanges: false,
        isInitValue: true,
      };
    },
    computed: {
      ...mapWritableState(useRateEntryStore, ['rateAttributes', 'editingRateAttributeValue']),
      ...mapState(useProductStore, ['productId', 'productState']),
      /**
       * Form prop for validation
       *
       * @returns {string}
       */
      formProp() {
        return this.formKey || this.cellData.label;
      },
      /**
       * Array of rate value to search through to find cell value
       *
       * @returns {Array}
       */
      rateValues() {
        const rateAttribute = this.rateAttributes[this.storeAttributeId];

        return rateAttribute ? rateAttribute.rateValues[this.subtypeId] : null;
      },
      /**
       * Value string of the cell to use in valueModel
       *
       * @returns {string}
       */
      value() {
        return this.rateValues
          ? this.rateValues.values.find((value) => value.label === this.cellData.label)?.value
          : null;
      },
      valueModel: {
        /**
         * Mapped to the rateEntryValues[subtypeId][label].value.
         * due to a timing issue with switching subtypes, the label could be switching so we need to account for that timing.
         *
         * @returns {string}
         */
        get() {
          const isValueZero = this.value === 0;
          const isNotChanged = this.isInitValue && this.value;

          // Initial Values will not come back with 3 decimal points so update only
          return isNotChanged || isValueZero
            ? parseFloat(this.value).toFixed(3)
            : this.value;
        },
        /**
         * Triggers `updateValue()` to update the value in the store.
         *
         * @param {string} value
         */
        set(value) {
          this.updateValue({ value });
        },
      },
    },
    methods: {
      /**
       * Removes all non-digits and special characters outside `.` and sets format to `X.XXX`.
       */
      onBlur() {
        if (!this.valueModel) {
          return;
        }
        const value = this.valueModel.replace(/[^0-9.]/g, '');

        this.updateValue({
          value: value && !Number.isNaN(Number(value))
            ? parseFloat(value).toFixed(3)
            : '',
        });
      },
      /**
       * Updates the store rateEntryValues[subtypeId][label].value
       *
       * @param {object} root0
       * @param {string} root0.value
       */
      updateValue({ value }) {
        // When the user updates a value we need to update rateErrors by emitting to the parent table
        this.$emit('validateForm');
        this.hasUnsavedChanges = true;
        this.editingRateAttributeValue = true;

        const rateValue = this.rateAttributes[this.storeAttributeId].rateValues[this.subtypeId].values
          .find((localValue) => localValue.label === this.cellData.label);

        this.$set(rateValue, 'value', value);
        this.$emit('updateValue', value);

        if (value) {
          this.isInitValue = false;
          this.$emit('updateAttributes', {
            productId: this.productId,
            productState: this.productState,
            storeAttributeId: this.storeAttributeId,
            subtypeId: this.subtypeId,
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  :deep() {
    .el-form-item.is-error {
      .el-input__inner {
        border-color: var(--tf-warning);
      }
    }
  }

  :deep(.el-form-item__error) {
    position: relative;
  }
</style>
