<template>
  <div class="container-attributes">
    <TableHeader :readonly="$route.meta.readonly">
      <template slot="title">
        {{ rateAttribute.name }}
      </template>
      <!-- Edit Plan Design Slot (when reviewing) -->
      <template #btnEdit>
        <RouterLink
          v-if="!isPlanSummary"
          :to="{ name: 'RateEntry' }"
        >
          <AppButton
            icon="fa-solid fa-pencil"
            size="text"
            text="Edit rates"
          />
        </RouterLink>
      </template>
      <!-- Rate Options/Rate Basis -->
      <template #formElements>
        <!-- Layout of Rate Entry page -->
        <template v-if="!isPlanSummary">
          <ElFormItem
            label="Rate options:"
            size="medium"
          >
            <ElSelect
              v-bind="{
                value: attributeType,
              }"
              :disabled="readonly"
              size="medium"
              placeholder="Select rate option"
              data-test="rate-option-select"
              @change="changeAttributeType"
            >
              <ElOption
                value="CompositeRateValue"
                :label="$options.filters.tierGroupName('CompositeRateValue')"
                :data-test="`rate-option-composite`"
              />
              <ElOption
                v-if="showAgeBandedValueOption"
                value="AgeBandedRateValue"
                :label="$options.filters.tierGroupName('AgeBandedRateValue')"
                :data-test="`rate-option-age-banded`"
              />
              <ElOption
                v-if="attributeInfo.policyAttributeType === 'CustomRateValue'"
                disabled
                value="CustomRateValue"
                :label="$options.filters.tierGroupName('CustomRateValue')"
              />
              <ElOption
                v-for="option in rateTierGroups"
                :key="`${rateAttribute.name}-${option.tier_group_id}`"
                :value="option.tier_group_id"
                :label="option.tier_group_name"
                :data-test="`rate-option-${option.tier_group_name}`"
              />
            </ELSelect>
          </ElFormItem>
          <ElFormItem
            :label="`Rate basis${rateBasisOptions.length === 1 ? ':' : ''}`"
            size="medium"
          >
            <ElSelect
              v-if="rateBasisOptions.length > 1"
              v-bind="{
                value: rateBasis
              }"
              :disabled="readonly"
              size="medium"
              placeholder="Select rate basis"
              @change="updateRateBasis"
            >
              <ElOption
                v-for="option in rateBasisOptions"
                :key="`${rateAttribute.name}-${option.value}`"
                :value="option.value"
                :label="option.label"
              />
            </ElSelect>
            <span
              v-else
              v-text="rateBasis"
            />
          </ElFormItem>
        </template>
        <!-- Layout for Plan Summary modal -->
        <template v-else>
          <h4>
            Rate options:
            <template v-if="isPlan">
              {{ policyValues.tier_group.name
                || policyValues.tier_group.tier_subtypes[0].rate_value.type | tierGroupName }}
            </template>
            <template v-else>
              {{ policyValues.tier_group.name
                || policyValues.tier_group.tier_subtypes[0].rate_values[0].type | tierGroupName }}
            </template>
          </h4>
          <h4>Rate basis: {{ rateBasis }}</h4>
        </template>
      </template>
    </TableHeader>
    <div
      v-if="!tierGroupsIsLoading"
      v-loading.body="editingRateBasis"
      class="attributes-tables"
    >
      <!-- Incumbent table -->
      <RateEntryTable
        v-if="policyValues.tier_group && !isNewCoverage"
        v-bind="{
          attributeType,
          incumbent: true,
          rateAttributeIndex,
          tierGroup: policyValues.tier_group }"
      />
      <!-- Proposal/Renewal table -->
      <RateEntryTable
        v-if="!isPlanSummary"
        v-bind="{
          id: rateAttribute.id,
          attributeType,
          rateAttributeIndex,
          tierGroup: proposalValues.tier_group }"
      />
    </div>
  </div>
</template>

<script>
  import { mapState, mapWritableState, mapActions } from 'pinia';
  import { useAccountStore } from '@/stores/account.js';
  import { useRateEntryStore } from '@/stores/rateEntry.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useProductStore } from '@/stores/product.js';
  import { reusableRateBasisOptions } from '@watchtowerbenefits/es-utils-public';
  import TableHeader from '@/components/DataEntry/TableHeader.vue';
  import RateEntryTable from './RateEntryTable.vue';

  /**
   * Rate Entry Attribute
   *
   * @exports RateEntry/RateEntryAttribute
   */
  export default {
    name: 'RateEntryAttribute',
    components: { RateEntryTable, TableHeader },
    /**
     * sends reactive storeAttributeId and containerId
     *
     * @returns {object}
     */
    provide() {
      const props = {};

      [
        'storeAttributeId',
        'containerId',
      ].forEach((prop) => {
        Object.defineProperty(props, prop, {
          get: () => this[prop],
        });
      });

      return props;
    },
    inject: [
      'containerIds',
      'isPlanSummary',
      'isPlan',
    ],
    props: {
      /**
       * Rate Entry Attribute containing the ID, name, and incumbent/proposal values
       */
      rateAttribute: {
        type: Object,
        required: true,
      },
      rateAttributeIndex: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        attributeType: null,
        rateBasis: null,
      };
    },
    computed: {
      ...mapWritableState(useRateEntryStore, [
        'rateAttributes',
        'editingRateBasis',
      ]),
      ...mapState(useRateEntryStore, [
        'info',
      ]),
      ...mapState(useProjectStore, ['projectId']),
      ...mapState(useProductStore, [
        'rateTierGroups',
        'tierGroupsIsLoading',
        'currentProduct',
        'isNewCoverage',
        'productId',
      ]),
      ...mapState(useAccountStore, ['userInfo']),
      /**
       * Attribute Information object from the store used for `attributeType` and `rateBasis`
       *
       * @returns {object}
       */
      attributeInfo() {
        return this.rateAttributes[this.storeAttributeId] || {};
      },
      /**
       * Show the age-banded value option only for dental, dental 2, vision, and stop loss products
       *
       * @returns {boolean}
       */
      showAgeBandedValueOption() {
        return !/dental|vision|stop loss/i.test(this.currentProduct.product_type_name);
      },
      /**
       * Policy values used for the incumbent tables
       *
       * @returns {object}
       */
      policyValues() {
        return this.rateAttribute.products.find((product) => product.document_type.toLowerCase() === 'policy');
      },
      /**
       * proposal/renewal values used for the right tables
       *
       * @returns {object}
       */
      proposalValues() {
        return this.rateAttribute.products.find((product) => product.document_type.toLowerCase() !== 'policy');
      },
      /**
       * Get the available Rate Basis Options based on the product type
       *
       * @returns {Array}
       */
      rateBasisOptions() {
        return reusableRateBasisOptions(this.info.product_type_id);
      },
      /**
       * Boolean to determine the state of the tables (review or edit)
       *
       * @returns {boolean}
       */
      readonly() {
        return this.$route.meta.readonly;
      },
      /**
       * storeAttributeId is passed down to children so they can point to the appropriate rateAttribute in the store
       *
       * @returns {string}
       */
      storeAttributeId() {
        return `${this.containerIds.join('_')}_${this.rateAttribute.id}`;
      },
    },
    created() {
      this.attributeType = this.attributeInfo.attributeType;

      let { rateBasis } = this.attributeInfo;

      if (this.isPlanSummary || this.rateBasisOptions.length === 1) {
        rateBasis = this.isPlan
          ? this.policyValues.tier_group.tier_subtypes[0].rate_value.formatted_rate_basis
          : this.policyValues.tier_group.tier_subtypes[0].rate_values[0].formatted_rate_basis;
      }

      this.rateBasis = rateBasis;
    },
    methods: {
      ...mapActions(useRateEntryStore, ['updateRateAttribute', 'setRateAttributeRateValues']),
      async changeAttributeType(newValue) {
        let value = newValue;
        let tierGroupId = null;

        this.rateAttributes[this.storeAttributeId].attributeType = value;

        await this.$nextTick();

        if (!['AgeBandedRateValue', 'CompositeRateValue', 'CustomRateValue'].includes(newValue)) {
          const tierGroup = this.rateTierGroups
            .find(({ tier_group_id: id }) => id === newValue);

          tierGroupId = tierGroup.tier_group_id;
          value = tierGroup.tier_group_name;
        }

        let subtypes = [];

        if (tierGroupId) {
          const tierGroup = this.rateTierGroups.find(
            ({ tier_group_id: id }) => id === tierGroupId,
          );

          subtypes = tierGroup.tier_subtypes;
        } else {
          subtypes.push({ subtype_id: value });
        }

        // we need to await the first action because it needs to finish its delete before
        // we can run the subsequent patch calls, therefore we're allowing the await in
        // this for loop.
        /* eslint-disable no-restricted-syntax */
        /* eslint-disable no-await-in-loop */
        subtypes.forEach(async (subtype) => {
          await this.updateRateAttribute({
            productId: this.productId,
            storeAttributeId: this.storeAttributeId,
            subtypeId: subtype.subtype_id,
            rateBasis: true,
            skipDelete: true,
          });
        });
        this.rateAttributes[this.storeAttributeId].tierGroupId = tierGroupId;
        this.attributeType = value;
      },
      async updateRateBasis(value) {
        this.rateAttributes[this.storeAttributeId].rateBasis = value;

        this.editingRateBasis = true;
        let subtypes = [];

        if (this.attributeInfo.tierGroupId) {
          const tierGroup = this.rateTierGroups.find(
            ({ tier_group_id: id }) => id === this.attributeInfo.tierGroupId,
          );

          subtypes = tierGroup.tier_subtypes;
        } else {
          subtypes.push({ subtype_id: this.attributeType });
        }

        // we need to await the first action because it needs to finish its delete before
        // we can run the subsequent patch calls, therefore we're allowing the await in
        // this for loop.
        /* eslint-disable no-restricted-syntax */
        /* eslint-disable no-await-in-loop */
        for (const [index, subtype] of subtypes.entries()) {
          await this.updateRateAttribute({
            productId: this.productId,
            storeAttributeId: this.storeAttributeId,
            subtypeId: subtype.subtype_id,
            skipDelete: index !== 0,
            rateBasis: true,
          });
        }
        /* eslint-enable no-restricted-syntax */
        /* eslint-enable no-await-in-loop */

        this.rateBasis = value;
        this.editingRateBasis = false;
      },
      /**
       * Evaluate plan or class based tier group values and dispatch the correct Rate Attribute value.
       */
      setRateAttribute() {
        const attributeType = this.proposalValues.tier_group?.name;
        let policyAttributeType;
        let rateValue;
        const rateValues = {};
        let value;

        if (this.isPlan) {
          policyAttributeType = this.policyValues.tier_group.name
            || this.policyValues.tier_group.tier_subtypes[0].rate_value.type;
          rateValue = this.proposalValues.tier_group?.tier_subtypes[0].rate_value;
        } else {
          policyAttributeType = this.policyValues.tier_group.name
            || this.policyValues.tier_group.tier_subtypes[0].rate_values[0].type;
          [rateValue] = this.proposalValues.tier_group?.tier_subtypes[0].rate_values || null;
        }

        this.proposalValues.tier_group.tier_subtypes.forEach((subtype) => {
          value = this.isPlan ? subtype.rate_value : subtype.rate_values[0];
          // Set `rateValues` key to composite/age-banded since there is no id associated with these
          // Otherwise set it to the subtypeId
          if (!subtype.id) {
            rateValues[value.type] = {
              type: value.type,
              values: value.values,
            };
          } else {
            rateValues[subtype.id] = {
              type: value.type,
              values: value.values,
            };
          }
        });

        const newAttribute = {
          attributeId: this.rateAttribute.id,
          attributeType: attributeType || rateValue?.type,
          containerIds: this.containerIds,
          originalTierGroupId: this.proposalValues.tier_group?.id,
          policyAttributeType,
          rateBasis: rateValue?.rate_basis,
          rateValues,
          tierGroupId: this.proposalValues.tier_group?.id || null,
        };

        this.$set(this.rateAttributes, this.storeAttributeId, newAttribute);
      },
    },
  };
</script>

<style lang="scss" scoped>
.attributes-tables {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  margin: 0 30px;

  .dialog-plan-summary & {
    margin: 0;
  }
}

.container-attributes {
  width: 100%;
  max-width: $max-width;
  margin: 0 auto;
}

h4 {
  font-weight: 400;

  .dialog-plan-summary & {
    font-size: 14px;
  }
}

:deep(.el-form-item) {
  margin-bottom: 10px;
}
</style>
