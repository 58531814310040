<template>
  <ElDialog
    :title="modalTitle"
    :visible="visible"
    data-test="start or update renewal dialog"
    top="70px"
    width="620px"
    @close="closeDialog"
  >
    <ElRadioGroup
      v-if="(allProductsNotStarted && isUploadRenewalRatePass) && !threeflowAssistSupported"
      v-model="renewalFormToDisplay"
      class="renewal-form-to-display-radios"
    >
      <ElRadio
        data-test="show Upload Renewal documents form"
        label="uploadDocuments"
      >
        Upload Renewal documents
      </ElRadio>
      <ElRadio
        data-test="show Rate pass form"
        label="ratePass"
      >
        Rate pass for all products without documents
      </ElRadio>
    </ElRadioGroup>
    <template v-if="renewalFormToDisplay === 'uploadDocuments'">
      <FileUpload
        :product-ids="baseProductIds"
        @refreshAndClose="refreshDataAndClose"
        @submitDocument="$emit('submitDocument', $event)"
      />
    </template>
    <template v-if="renewalFormToDisplay === 'ratePass'">
      <SetRateGuarantee
        :selected-products="baseProductIds"
        :products="products"
        :btn-saving="btnSaving"
        @updateBtnSaving="btnSaving = $event"
        @refreshAndClose="refreshDataAndClose"
      />
    </template>
  </ElDialog>
</template>

<script>

  import { mapActions, mapState } from 'pinia';
  import project from '@/mixins/project.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useProductStore } from '@/stores/product.js';
  import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';
  import SetRateGuarantee from './SetRateGuarantee.vue';
  import FileUpload from './FileUpload.vue';

  /**
   * Start or update renewal modal dialog. Conditionally displays Rate Pass/Rate Guarantee form or Upload Renewal documents form.
   *
   * @exports Modals/StartOrUpdateRenewalModal
   */
  export default {
    name: 'StartOrUpdateRenewalModal',
    components: {
      FileUpload,
      SetRateGuarantee,
    },
    mixins: [project],
    props: {
      allProductsNotStarted: {
        type: Boolean,
        required: true,
      },
      visible: {
        type: Boolean,
        default: false,
      },
      products: {
        type: Array,
        default: () => [],
      },
      documentId: {
        type: Number,
        required: true,
      },
    },
    data: () => ({
      btnSaving: false,
      fetchError: false,
      renewalFormToDisplay: 'uploadDocuments',
    }),
    computed: {
      ...mapState(useProductStore, [
        'isUploadRenewalRatePass',
        'smartDocType',
        'threeflowAssistSupported',
      ]),
      ...mapState(useProjectStore, [
        'projectId',
        'getDocumentsAndRefreshProducts',
      ]),
      /**
       * If every product is in a Not Started state, the modal is a Start Renewal modal
       * Otherwise, it's an Update Renewal Modal
       *
       * @returns {boolean}
       */
      isUpdateModal() {
        return !this.products.every(({ state }) => /not_started/i.test(state));
      },
      /**
       * Display Start Renewal/Quote if every product is in the Not Started state otherwise display Update Renewal/Quote.
       *
       * @returns {string}
       */
      modalTitle() {
        const quoteOrRenewal = this.smartDocType(true);
        const updateOrStart = this.isUpdateModal ? 'Update' : 'Start';

        return this.threeflowAssistSupported
          ? 'Send to ThreeFlow Assist' : `${updateOrStart} ${quoteOrRenewal}`;
      },
      /**
       * Filter out any alternatives and then condense the products into an array of product ids.
       *
       * @returns {Array}
       */
      baseProductIds() {
        return this.products.reduce((acc, { id, project_product: { alternative } }) => {
          if (!alternative) {
            acc.push(id);
          }

          return acc;
        }, []);
      },
    },
    created() {
      const startOrUpdateModalOpenedMessage = this.modalTitle.replace(/^(Start|Update)/, '$1 Smart');

      trackSegmentEvent(startOrUpdateModalOpenedMessage, {
        project_id: this.projectId,
      });
    },
    methods: {
      ...mapActions(useProjectStore, ['getProjectDocuments']),
      /**
       * Disables saving state and closes the dialog.
       */
      closeDialog() {
        this.btnSaving = false;
        this.$emit('closeDialog', this.renewalFormToDisplay);
      },
      /**
       * Updates the project info, refreshes the page, and closes the dialog.
       */
      async refreshDataAndClose() {
        const action = this.isUpdateModal ? 'update' : 'start';

        await this.getDocumentsAndRefreshProducts(this.carrierId, this.projectId, action);
        this.closeDialog();
      },
    },
  };
</script>

<style lang="scss" scoped>
  :deep(.el-icon-close:before) {
    color: var(--tf-blue);
  }

  :deep(.inner-header) {
    margin-top: 32px;
  }

  :deep(.el-dialog__body) {
    padding: 0 40px;
  }

  :deep(.btn-group) {
    margin: 16px 0 24px;
  }

  .renewal-form-to-display-radios {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    line-height: 2;

    .el-radio {
      color: inherit;
      font-weight: 700;

      &:not(first-child) {
        margin: 0;
      }
    }

    :deep(.el-radio__input.is-checked .el-radio__inner) {
      border-color: var(--tf-blue);
    }

    :deep(.is-checked .el-radio__label) {
      color: var(--tf-blue);
    }

    :deep(.el-radio__inner::after) {
      background-color: var(--tf-blue);
    }
  }
</style>
