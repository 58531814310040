<template>
  <ElDialog
    :title="modalTitle"
    :visible="visible"
    :before-close="onBeforeClose"
    class="rate-pass-renew-dialog"
    data-test="rate pass renew by product dialog"
    top="70px"
    width="620px"
  >
    <template v-if="step === 'one'">
      <ProductSelection
        :btn-saving="btnSaving"
        :products="products"
        :selected-products.sync="selectedProducts"
        :upload-enhancements-enabled="uploadRenewalUploadEnhancementsEnabled"
        @updateStep="step = $event"
      />
    </template>
    <template v-if="step === 'two'">
      <SetRateGuarantee
        :selected-products="selectedProducts"
        :products="products"
        :upload-enhancements-enabled="uploadRenewalUploadEnhancementsEnabled"
        @updateBtnSaving="btnSaving = $event"
        @updateStep="step = $event"
        @refreshAndClose="refreshDataAndClose"
      />
    </template>
    <template v-if="step === 'three' && uploadRenewalUploadEnhancementsEnabled">
      <FileUpload
        :product-ids="unselectedProducts"
        @refreshAndClose="refreshDataAndClose"
      />
    </template>
  </ElDialog>
</template>

<script>

  import { mapActions, mapState } from 'pinia';
  import project from '@/mixins/project.js';
  import { uploadRenewalUploadEnhancements } from '@/utils/featureFlags.js';
  import { useProjectStore } from '@/stores/project.js';
  import ProductSelection from './ProductSelection.vue';
  import SetRateGuarantee from './SetRateGuarantee.vue';
  import FileUpload from './FileUpload.vue';

  /**
   * Container for and handles the step logic for the
   * RatePassRenewalByProduct steps.
   *
   * @exports Modals/RatePassRenewByProductModal
   */
  export default {
    //  rff:uploadRenewalUploadEnhancements - This component can likely be deleted when feature flag is removed, as long as the Product Cards have been phased out -->
    name: 'RatePassRenewByProductModal',
    components: {
      FileUpload,
      ProductSelection,
      SetRateGuarantee,
    },
    mixins: [project],
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      products: {
        type: Array,
        default: () => [],
      },
      startStep: {
        type: String,
        default: 'one',
      },
      documentId: {
        type: Number,
        required: true,
      },
    },
    data: () => ({
      btnSaving: false,
      fetchError: false,
      selectedProducts: [],
      step: 'one',
    }),
    computed: {
      ...mapState(useProjectStore, [
        'projectId',
        'getDocumentsAndRefreshProducts',
      ]),
      /**
       * Determines modal title based on step.
       *
       * @returns {string}
       */
      modalTitle() {
        // after ff uploadRenewalUploadEnhancements is on in prod you can replace function body with this
        // const steps = {
        //   one: 'select product',
        //   two: 'set product rate guarantees',
        //   three: 'upload renewal documents',
        // };
        // return `Step ${this.step}: ${steps[this.step]}`;

        const steps = {
          one: {
            stepNumber: this.uploadRenewalUploadEnhancementsEnabled ? 'one' : '1',
            stepTitle: 'select product',
          },
          two: {
            stepNumber: this.uploadRenewalUploadEnhancementsEnabled ? 'two' : '2',
            stepTitle: 'set product rate guarantees',
          },
          three: {
            stepNumber: this.uploadRenewalUploadEnhancementsEnabled ? 'three' : '3',
            stepTitle: 'upload renewal documents',
          },
        };

        return `Step ${steps[this.step].stepNumber}: ${steps[this.step].stepTitle}`;
      },
      /**
       * Evaluate the uploadRenewalUploadEnhancements feature flag.
       *
       * @returns {boolean}
       */
      uploadRenewalUploadEnhancementsEnabled() {
        return this.$ld.checkFlags(uploadRenewalUploadEnhancements);
      },
      /**
       * Evaluate if any products are selected in step one.
       *
       * @returns {boolean}
       */
      noProductsSelected() {
        return this.selectedProducts.length === 0;
      },
      /**
       * Products selected for renewal but not selected for rate pass
       *
       * @returns {Array}
       */
      unselectedProducts() {
        return this.products.filter(({ id }) => !this.selectedProducts.includes(id)).map(({ id }) => id);
      },
    },
    mounted() {
      this.step = this.startStep;
    },
    methods: {
      ...mapActions(useProjectStore, ['getProjectDocuments']),
      closeDialog() {
        this.btnSaving = false;
        this.selectedProducts = [];
        this.$emit('closeDialog', this.step, this.selectedProducts);
        this.step = 'one';
      },
      /**
       * If you click the 'x' make sure the dialog closes.
       *
       * @param {Function} done
       */
      onBeforeClose(done) {
        if (this.step === 'three' && !this.noProductsSelected
          && (this.selectedProducts.length < this.products.length)) {
          this.refreshDataAndClose();
          done();

          return;
        }
        this.closeDialog();
        done();
      },
      /**
       * Updates the project info, refreshes the page, and closes the dialog.
       */
      async refreshDataAndClose() {
        // this cannot be called at the end of step 2 if going to step 3
        await this.getDocumentsAndRefreshProducts(this.carrierId, this.projectId);

        this.closeDialog();
      },
    },
  };
</script>

<style lang="scss" scoped>
  :deep(.el-icon-close:before) {
    color: var(--tf-blue);
  }

  :deep(.inner-header) {
    margin-top: 32px;
  }

  :deep(.el-dialog__body) {
    padding: 0 40px;
  }

  :deep(.btn-group) {
    margin: 16px 0 24px;
  }
</style>
